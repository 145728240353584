<template>
  <div class="changgephone">
    <div class="header">
      <div class="left">
        <van-icon name="arrow-left" @click="gopath('back')" class="icon" />
      </div>
      <div class="title">校验手机</div>
    </div>
    <div class="box" v-if="!exitFlag">
      <div class="title">为确保是您本人操作，请完成以下验证</div>
      <div class="text">我们已经发送了校验证码到您的手机：</div>
      <div class="phone">{{ mobileshow }}</div>
      <div class="input">
        <van-field
          v-model="sms"
          @input="changeInput"
          center
          clearable
          maxlength="4"
          placeholder="请输入验证码"
        >
          <template #button>
            <van-button @click="getSms" size="small" type="info">{{
              flag ? second + "秒" : tip
            }}</van-button>
          </template>
        </van-field>
      </div>
    </div>
    <div v-else class="box">
      <van-field
        v-model="mobilequ"
        maxlength="11"
        class="input"
        placeholder="请输入手机号"
      />
      <van-field
        v-model="smsqu"
        @input="changeInput"
        center
        clearable
        maxlength="4"
        placeholder="请输入验证码"
      >
        <template #button>
          <van-button @click="getSms" size="small" type="info">{{
            flag ? second + "秒" : tip
          }}</van-button>
        </template>
      </van-field>
    </div>
    <div class="submit" @click="postnew">完成</div>
  </div>
</template>

<script>
import { mobile_send } from "@/api/login.js";
import { mobileTest, bangdi } from "@/api/user.js";
export default {
  data() {
    return {
      sms: "",
      tip: "获取验证码",
      second: 60,
      flag: false,
      userInfo: {},
      mobileshow: "",
      exitFlag: true,
      mobilequ: "",
      smsqu: "",
    };
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo")).data;
    this.mobileshow =
      this.userInfo.mobile.substring(0, 3) +
      "****" +
      this.userInfo.mobile.substr(this.userInfo.mobile.length - 4);
  },
  methods: {
    gopath(val) {
      if (val == "back") {
        this.$router.go(-1);
      }
    },
    getSms() {
      if (this.flag) {
        return;
      }
      this.flag = true;
      let that = this;
      var timeer = setInterval(function () {
        if (that.second == 0) {
          that.flag = false;
          that.second = 60;
          clearInterval(timeer);
        }
        that.second--;
      }, 1000);
      let data = {
        mobile: this.userInfo.mobile,
        type: 4,
      };
      mobile_send(data).then((res) => {});
    },
    changeInput() {
      if (this.sms.length == 4) {
        let obj = {
          mobile: this.mobile,
          smsCode: this.sms,
        };
        mobileTest(obj).then((res) => {
          this.exitFlag = true;
          //   this.$router.replace("/home");
        });
      }
    },
    postnew() {
      if (!this.smsqu || this.smsqu.length != 4) {
        this.$toast("请正确输入验证码");
        return;
      }
      var patt = /^[1][3-9][\d]{9}/;
      if (!patt.test(this.mobilequ)) {
        this.$toast("请正确输入手机号");
        return;
      }
      let obj = {
        mobile: this.mobilequ,
        smsCode: this.smsqu,
      };
      bangdi(obj).then((res) => {
        if (res.data.code == 0) {
          this.$router.replace("/my");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.changgephone {
  position: relative;
}
.header {
  padding: 10px 10px;
  display: flex;
  .left {
    width: 10%;
    .icon {
      font-size: 24px;
      font-weight: 550;
      color: #141414;
    }
  }
  .title {
    width: 85%;
    text-align: center;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141414;
  }
}
.box {
  background: #f8f8f8;
  width: 100%;
  min-height: 86vh;
  text-align: center;
  padding: 48px 0 0 0;
  .title {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 23px;
    margin: 5px 0 5px 0;
  }
  .text {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 17px;
  }
  .phone {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141414;
    line-height: 23px;
    margin: 8px 0 15px 0;
  }
  .input {
    margin-bottom: 14px;
  }
}
.van-cell {
  margin-bottom: 5px;
  border-radius: 5px;
  .van-button {
    background: #fff;
    color: #5f7dff;
    border-right: none;
    border-bottom: none;
    border-top: none;
    border-color: #dddddd;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
}
.van-button {
  width: 100px;
}
.submit {
  position: absolute;
  bottom: 60vh;
  width: 96%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  background: #5f7dff;
  color: #fff;
  padding: 10px 0;
  border-radius: 5px; /*no*/
  margin-top: 40%;
}
</style>